//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
export class DebounceService{
    //from https://davidwalsh.name/javascript-debounce-function
    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    debounce(func: () => void, wait: number, immediate: boolean = false) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    debounce_run(func: () => void, wait: number, immediate: boolean = false){
        this.debounce(func,wait,immediate)();
    }
}