import { InitialLayerConfig } from "./InitializationService";
import { CameraPosition, CameraPositionTargetChangedEvent, PositionService } from "./PositionService";
import { ServiceBarrier, Services } from "./Services";
import { CurrentTimeChangedEvent } from "./TimeService";

interface SynchronizationMessage{
    type: string,
    data: object
}

interface CameraPositionMessage extends SynchronizationMessage {
    type: "CameraPosition";
    data:{
        position: CameraPosition
    };
}

interface TimeRangeMessage extends SynchronizationMessage {
    type: "TimeRange",
    data:{
        min: number,
        max: number
    }
}

interface ReloadMessage extends SynchronizationMessage{
    type: "Reload",
    data: {}
}

interface JoinedMessage extends SynchronizationMessage{
    type: "Joined",
    data: {}
}

interface SceneMessage extends SynchronizationMessage{
    type: "Scene",
    data: {
        scene: InitialLayerConfig
    }
}

export class SynchronizationService{
    websocket: WebSocket = null;
    lastMessagesByType = {
        "Reload": new Date().getTime(),
        "TimeRange": new Date().getTime(),
        "CameraPosition": new Date().getTime(),
        "Joined": new Date().getTime()
    }
    constructor(){
        ServiceBarrier.wait().then(() => {
            //this.tryStart();
        });
    }

    public stop(){
        console.log("Stopping sync");
        if(this.websocket != null){
            this.websocket.close();
            console.log("Stopped sync");
        }
    }

    private sendSocket(message: SynchronizationMessage){
        if(this.websocket.readyState == WebSocket.OPEN)
        this.websocket.send(JSON.stringify(message));
    }

    public syncScene(){
        let message: SceneMessage = {
            type: "Scene",
            data: {
                scene: Services.InitializationService.getInitialLayerConfig()
            }
        };
        console.log("sync", message);
        this.sendSocket(message);
    }

    public tryStart(){
        if(Services.InitializationService.SceneName != ""){
            let SYNCSERVER_URL = Services.InitializationService.SERVER_URL.replace("http", "ws") + "/sync/" + Services.InitializationService.SceneName;
            console.log("Connecting to ", SYNCSERVER_URL);
            this.websocket = new WebSocket(SYNCSERVER_URL);
            this.websocket.onopen = () => {
                console.info("Synchronization websocket connected");
                let joinmsg: JoinedMessage = {
                    type: "Joined",
                    data: {}
                }
                this.sendSocket(joinmsg);
            }

            this.websocket.onclose = () => {
                console.warn("Lost synchronization connection");
                setTimeout(this.tryStart, 100);
            }

            this.websocket.onmessage = (msg) => {
                try{
                    let message: SynchronizationMessage = JSON.parse(msg.data);
                    console.log(message);
                    let messagetype = message.type;
                    switch (messagetype){
                        case "Reload":
                            (()=>{
                                let t = new Date().getTime();
                                console.log("Since last reload: ", t - this.lastMessagesByType["Reload"]);
                                if(t - this.lastMessagesByType["Reload"] > 15000){
                                    window.location.reload();
                                }
                            })();
                        break;
                        case "Joined":
                            (() => {
                                let t = new Date().getTime();
                                if(t - this.lastMessagesByType["CameraPosition"] > 1000){
                                    let posmsg: CameraPositionMessage = {
                                        type: "CameraPosition",
                                        data: {
                                            position: Services.PositionService.getCameraPosition()
                                        }
                                    };
                                    this.sendSocket(posmsg);
                                }
                                if(t - this.lastMessagesByType["TimeRange"] > 1000){
                                    let trange = Services.TimeService.getCurrentTimeRange();
                                    let tmsg: TimeRangeMessage = {
                                        type: "TimeRange",
                                        data: {
                                            min: trange[0],
                                            max: trange[1]
                                        }
                                    }
                                    this.sendSocket(tmsg);
                                }
                            })();
                        case "CameraPosition":
                            (() => {
                                let positionMessage = message as CameraPositionMessage;
                                Services.PositionService.setCameraPosition(positionMessage.data.position, true);
                            })();
                        break;
                        case "TimeRange":
                            (() => {
                                let timeMessage = message as TimeRangeMessage;
                                Services.TimeService.setCurrentTimeRange(timeMessage.data.min, timeMessage.data.max, false, true);
                            })();
                        break;
                        case "Scene": {
                            (() => {
                                let sceneMessage = message as SceneMessage;
                                console.log("Received new scene", sceneMessage.data);
                                Services.InitializationService.load_initial_layers(sceneMessage.data.scene);
                                console.log("Loaded new scene");
                            })();
                            break;
                        }
                        default: 
                            console.warn("Unknown synchronization message", message);
                    }
                    this.lastMessagesByType[messagetype] = new Date().getTime();
                 }catch{}
            }

            window.addEventListener("beforeunload", () => {
                let message: ReloadMessage = {
                    type: "Reload",
                    data: {}
                };
                this.sendSocket(message);
            });

            Services.PositionService.addEventListener("CameraPositionTargetChanged", (pos: CameraPositionTargetChangedEvent) => {
                let message: CameraPositionMessage = {
                    type: "CameraPosition",
                    data: {
                        position: pos.position
                    }
                };
                this.sendSocket(message);
            });


            Services.TimeService.addEventListener("CurrentTimeChanged", (time_event: CurrentTimeChangedEvent) => {
                if(time_event.external){
                    return;
                }
                let message: TimeRangeMessage = {
                    type: "TimeRange",
                    data: {
                        min: time_event.time_min,
                        max: time_event.time_max
                    }
                };
                this.sendSocket(message);
            })
        }
    }
}