//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { Services } from './Services';
import { SourceLayerInfo } from './SourceInfoService';
import { Tile, UECArea } from '../modules/tile';
import { glenv } from './RenderService';

export class StitchedTileData {
    public origin: StitchedTileRequest; 
    private float: boolean;
    public texture: WebGLTexture;
    public coord_offset: [number, number];
    public coord_scale: [number, number];
    public framesUnused: number = 0;
    public framebuffer: WebGLFramebuffer;

    constructor(env: glenv, origin: StitchedTileRequest, bounds: UECArea, float: boolean, size: number, depth_rb: WebGLRenderbuffer){
        let gl: WebGLRenderingContext = env.gl;
        this.origin = origin;
        this.float = float;
        this.texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.texture);
        if(float)
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, size, size, 0, gl.RGBA, gl.FLOAT, null);
        else
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, size, size, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        this.framebuffer = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, this.framebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.texture, 0);
        gl.bindRenderbuffer(gl.RENDERBUFFER, depth_rb);
        gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, depth_rb);
        this.updateBounds(bounds);
        this.framesUnused = 0;
    }

    updateBounds(bounds: UECArea){ 
        this.coord_offset = [bounds.position.x, bounds.position.y];
        this.coord_scale = [bounds.extent.x, bounds.extent.y];

        this.origin.sources.filter(x => x.layer.extent).forEach(x => {
            let my_extent = x.layer.extent;
            let mbx0 = Math.max(bounds.position.x, my_extent.position.x);
            let mby0 = Math.max(bounds.position.y, my_extent.position.y);
            let mbx1 = Math.min(bounds.position.x + bounds.extent.x, my_extent.position.x + my_extent.extent.x);
            let mby1 = Math.min(bounds.position.y + bounds.extent.y, my_extent.position.y + my_extent.extent.y);
            this.coord_offset = [mbx0, mby0];
            this.coord_scale = [mbx1 - mbx0, mby1 - mby0];
        });
    }

    updateSize(env: glenv, size: number){
        let gl = env.gl;
        let float = this.float;
        gl.deleteTexture(this.texture);
        this.texture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, this.texture);
        if(float)
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, size, size, 0, gl.RGBA, gl.FLOAT, null);
        else
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, size, size, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    }

    remove(env: glenv){
        env.gl.deleteTexture(this.texture);
        env.gl.deleteFramebuffer(this.framebuffer);
    }
}

class StitchedTileRequest {
    sources: SourceLayerInfo[];
    time: number;
    height: number;
    stitched_type: string;

    constructor(sources: SourceLayerInfo[], time: number, height: number, type: string){
        this.sources = sources;
        this.time = time;
        this.height = height;
        this.stitched_type = type;
    }
}

export class StitchedTilesService {

    private require_new_stitching: boolean = true;

    private stitched_textures: Map<string, StitchedTileData>
    private requested_stitched_textures: StitchedTileRequest[] = [];
    
    private resolution: number = 1024;

    private shared_depth_buffer: WebGLRenderbuffer;

    private gl: glenv;

    constructor(gl: glenv){
        this.gl = gl;
        this.stitched_textures = new Map();

        Services.RequiredTilesService.addEventListener("RequiredTilesChanged", (e) => {
            this.require_new_stitching = true;
        })
        let gl_: WebGLRenderingContext = gl.gl;
        this.shared_depth_buffer = gl_.createRenderbuffer();
        gl_.bindRenderbuffer(gl_.RENDERBUFFER, this.shared_depth_buffer);
        gl_.renderbufferStorage(gl_.RENDERBUFFER, gl_.DEPTH_COMPONENT16, this.resolution, this.resolution);
    }

    private get_gl(): WebGLRenderingContext{
        return this.gl.gl;
    }

    prepareStitchedTiles() {
        let bounds = Services.RequiredTilesService.getTilespaceBounds();
        let req_tiles = Services.RequiredTilesService.getRequiredTiles();
        this.stitched_textures.forEach((v, k) => {
            v.framesUnused += 1;
            if(v.framesUnused > 32){
                v.remove(this.gl);
                this.stitched_textures.delete(k);
            }
        });
        if(this.require_new_stitching){
            this.require_new_stitching = false;
            this.stitched_textures.forEach((v) => {
                v.updateBounds(bounds);
                this.renderStitchedTile(v, req_tiles);
            });   
        }
        this.requested_stitched_textures.forEach((r) => {
            let path = this.stitchedTilePath(r.sources, r.time, r.height);
            let s_tile = new StitchedTileData(this.gl, r, bounds, r.stitched_type != "ColorTiles", this.resolution, this.shared_depth_buffer);
            this.renderStitchedTile(s_tile, req_tiles);
            this.stitched_textures.set(path, s_tile);
        });
        this.requested_stitched_textures = [];
    }

    renderStitchedTile(target: StitchedTileData, tiles: Tile[]){
        let gl: WebGLRenderingContext = this.gl.gl;
        let shader = Services.GLService.Modules.stitching.stitching;
        let buff = Services.GLService.Geometries.stitching;
        gl.bindFramebuffer(gl.FRAMEBUFFER, target.framebuffer);
        gl.enable(gl.DEPTH_TEST);
        gl.viewport(0, 0, this.resolution, this.resolution);
        gl.useProgram(shader.program);
        gl.enableVertexAttribArray(shader.attributes["position"]);
        gl.bindBuffer(gl.ARRAY_BUFFER, buff.buffer);
        gl.vertexAttribPointer(shader.attributes["position"], 3, gl.FLOAT, false, 0, 0);
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        tiles.forEach(tile => {
            let texs = target.origin.sources.map(x => Services.TileCacheService.get_tile_data(x, tile, target.origin.time, target.origin.height));
            if(texs.some(x => !x)){
                this.require_new_stitching = true;
                return;
            }
            gl.uniform2f(
                shader.uniforms["tile_coord"],
                (tile.position.x - target.coord_offset[0]) / target.coord_scale[0],
                (tile.position.y - target.coord_offset[1]) / target.coord_scale[1]
            );
            gl.uniform2f(
                shader.uniforms["tile_size"],
                tile.size.x / target.coord_scale[0],
                tile.size.y / target.coord_scale[1]
            );
            for(var i = 0; i < 4; i++){
                if(i < texs.length){
                    gl.uniform1i(shader.uniforms["texture" + i + "_active"], 1);
                    gl.uniform2f(shader.uniforms["texture" + i + "_coord_offset"], texs[i].coord_offset[0], texs[i].coord_offset[1]);
                    gl.uniform2f(shader.uniforms["texture" + i + "_coord_scale"], texs[i].coord_scale[0], texs[i].coord_scale[1]);

                    gl.activeTexture(gl.TEXTURE0 + i);
                    gl.bindTexture(gl.TEXTURE_2D, texs[i].texture);
                    gl.uniform1i(shader.uniforms["texture" + i], i);
                } else {
                    gl.uniform1i(shader.uniforms["texture" + i  + "_active"], 0);

                }
            }

            gl.drawArrays(buff.mode, buff.start, buff.length);
        });
    }

    stitchedTilePath(sources: SourceLayerInfo[], time: number, height: number): string {
        return sources.map(x => 
            x.instance_name + "/" + 
            x.layer_name + "/" + 
            x.resolve_time(time, time)[0] + "/" +
            x.resolve_height(height)).join("/");
    }

    getStitchedScalarTiles(source: SourceLayerInfo, time: number, height: number): StitchedTileData {
        if(source.layer.layer_type != "ScalarTiles") return;
        let p = this.stitchedTilePath([source], time, height);
        if(this.stitched_textures.has(p)){
            let t = this.stitched_textures.get(p);
            t.framesUnused = 0;
            return t;
        }
        this.requested_stitched_textures.push(new StitchedTileRequest([source], time, height, "ScalarTiles"));
    }

    getStitchedColorTiles(source: SourceLayerInfo, time: number, height: number): StitchedTileData {
        if(source.layer.layer_type != "ColorTiles") return;
        let p = this.stitchedTilePath([source], time, height);
        if(this.stitched_textures.has(p)){
            let t = this.stitched_textures.get(p);
            t.framesUnused = 0;
            return t;
        }
        this.requested_stitched_textures.push(new StitchedTileRequest([source], time, height, "ColorTiles"));
    }


    getStitchedVector2Tiles(sourceu: SourceLayerInfo, sourcev: SourceLayerInfo, time: number, height: number): StitchedTileData {
        if(sourceu.layer.layer_type != "ScalarTiles" || sourcev.layer.layer_type != "ScalarTiles")  return;
        let p = this.stitchedTilePath([sourceu, sourcev], time, height);
        if(this.stitched_textures.has(p)){
            let t = this.stitched_textures.get(p);
            t.framesUnused = 0;
            return t;
        }
        this.requested_stitched_textures.push(new StitchedTileRequest([sourceu, sourcev], time, height, "Vector2Tiles"));
    }
}