//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer
import { Parameter } from "../Parameter";
import { Services } from "../../services/Services";
import { Mat4 } from "../vecmat";
import { RenderSource, RenderSourceSlot, EARTH_RADIUS } from "./RenderSource";
import { StitchedTileData } from "../../services/StitchedTilesService";
import { UEC, UECArea } from "../tile";

export class TileRenderSource extends RenderSource {

    constructor() {
        super();
        //@ts-ignore
        this.shaders = Services.GLService.Modules.sources.tileSource;
        this.name = "TileRenderSource";
        this.parameters = {
            displacement_scale: Services.SettingsService.getSetting("Exaggeration"),
            displacement_offset: new Parameter("Vertical Offset", 0, "number", true),
            z_level: new Parameter("Z Level", 0,"select", true)
        };
        this.parameters["displacement_offset"].shader_name = "displacement_offset";
        this.parameters["z_level"].shader_name = "z_level";
        this.parameters["z_level"].addOption(0);
        this.slots = {
            "displacement": new RenderSourceSlot(
                "Displacement Layer",
                "displacement",
                null,
                "tile",
                null
            ),
            "data0": new RenderSourceSlot(
                "Data Layer 0",
                "data0",
                null,
                "tile",
                Mat4.identity()
            ),
            "data1": new RenderSourceSlot(
                "Data Layer 1",
                "data1",
                null,
                "tile",
                Mat4.identity()
            ),
            "data2": new RenderSourceSlot(
                "Data Layer 2",
                "data2",
                null,
                "tile",
                Mat4.identity()
            ),
            "data3": new RenderSourceSlot(
                "Data Layer 3",
                "data3",
                null,
                "tile",
                Mat4.identity()
            )
        };
    }

    getVerticalBoundsWorldSpace(): [number, number] {
        if(this.slots["displacement"]?.source?.layer?.datarange){
            let min_scaled = this.applyScaling(this.slots["displacement"].source.layer.datarange[0]);
            let max_scaled = this.applyScaling(this.slots["displacement"].source.layer.datarange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getVerticalBoundsNative(): [number, number] {
        if(this.slots["displacement"]?.source?.layer?.datarange){
            let min_scaled = this.applyOffset(this.slots["displacement"].source.layer.datarange[0]);
            let max_scaled = this.applyOffset(this.slots["displacement"].source.layer.datarange[1]);
            return [
                Math.min(min_scaled, max_scaled),
                Math.max(min_scaled, max_scaled)
            ];
        }
        return[1, 1];
    }

    getExtent(): UECArea {
        let e_layers = Object.values(this.slots).filter((v: RenderSourceSlot) => 
            v.shaderName != "displacement" && v.source?.layer?.extent
        ).map(v => v.source.layer.extent);
        if (e_layers.length > 0) return e_layers.reduce((b, nb) => {
            let tlc = new UEC(Math.max(nb.position.x, b.position.x), Math.max(nb.position.y, b.position.y));
            let brc = new UEC(Math.min(nb.position.x + nb.extent.x, b.position.x + b.extent.x), Math.min(nb.position.y + nb.extent.y, b.position.y + b.extent.y));
            return new UECArea(tlc, new UEC(brc.x - tlc.x, brc.y - tlc.y));
        }, new UECArea(new UEC(0, 0), new UEC(1, 1)));
    }

    applyScaling(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                * this.parameters["displacement_scale"].value
                / EARTH_RADIUS
    }

    applyOffset(val: number): number {
        return 1 + (val + this.parameters["displacement_offset"].value)
                / EARTH_RADIUS
    }

    /*
     * Only run this function once the gl context has been prepared. It requires the correct color attachments to be set.
     */
    execute(context: { [name: string]: WebGLRenderingContext | any; }) {
        super.execute(context);

        let time = Services.TimeService.getMeanTime();
        let height = 0;
        if(!Object.values(this.slots).some((slot) => slot?.source?.layer))return;
        if(this.slots["data0"]?.source?.layer?.zsteps){
            height = parseFloat(this.parameters["z_level"].value);
        }

        context.gl.enable(context.gl.DEPTH_TEST);
        context.gl.enableVertexAttribArray(this.shader.attributes["position"]);
        let buff = Services.GLService.Geometries.tile;
        context.gl.bindBuffer(context.gl.ARRAY_BUFFER, buff.buffer);
        context.gl.vertexAttribPointer(this.shader.attributes["position"], 2, context.gl.FLOAT, false, 0, 0);

        Object.values(this.slots).forEach(slot => {
            if (slot.source) {
                context.gl.uniform1i(this.shader.uniforms[slot.shaderName + "_active"], true);
                if (slot.mixing)
                    context.gl.uniformMatrix4fv(this.shader.uniforms[slot.shaderName + "_mixing"], false, slot.mixing.as_typed());
            }
            else {
                context.gl.uniform1i(this.shader.uniforms[slot.shaderName + "_active"], false);
            }

        });
        let st: StitchedTileData;
        let req_tiles;
        let layer_extent = this.getExtent() || new UECArea(new UEC(0, 0), new UEC(1, 1));
        if(this.slots["displacement"].shaderName == "displacement" && this.slots["displacement"].source){
            req_tiles = Services.RequiredTilesService.getRequiredTilesBoundedDisplaced(layer_extent, this.slots["displacement"].source, time, height, (x) => this.applyScaling(x));

            st = Services.StitchedTilesService.getStitchedScalarTiles(this.slots["displacement"].source, time, height);
            if(st){
                context.gl.activeTexture(context.gl.TEXTURE0);
                context.gl.bindTexture(context.gl.TEXTURE_2D, st.texture);
                context.gl.uniform1i(this.shader.uniforms["stitched_displacement_map"], 0);
            }
        } else {
            req_tiles = Services.RequiredTilesService.getRequiredTilesBounded(layer_extent, [this.applyScaling(0), this.applyScaling(0)]);
        }
        let cam_position = Services.PositionService.getCameraPositionFiltered();
        req_tiles.forEach(tile => {
            switch (Services.PositionService.projection_mode) {
                case "EQUIRECT": {
                    if(cam_position.Longitude < -90 && tile.position.x > 0.5) {
                        context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(tile.size.x, 0, 0, tile.position.x - 1, 0, tile.size.y, 0, tile.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    } else if (cam_position.Longitude > 90 && tile.position.x < 0.5) {
                        context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(tile.size.x, 0, 0, tile.position.x + 1, 0, tile.size.y, 0, tile.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    } else {
                        context.gl.uniformMatrix4fv(this.shader.uniforms["viewMatrix"], false, Services.PositionService.world_transform.mul_mat4(new Mat4(tile.size.x, 0, 0, tile.position.x, 0, tile.size.y, 0, tile.position.y, 0, 0, 1, 0, 0, 0, 0, 1)).as_typed());
                    }
                        break;
                }
                case "SPHERE": {
                    break;
                }
                case "POLAR": {
                    break;
                }
            }
            context.gl.uniform2f(this.shader.uniforms["tile_coord"], tile.position.x, tile.position.y);
            context.gl.uniform2f(this.shader.uniforms["tile_size"], tile.size.x, tile.size.y);
            if(st){
                context.gl.uniform2f(
                    this.shader.uniforms["stitched_displacement_coord_offset"],
                    (tile.position.x - st.coord_offset[0]) / st.coord_scale[0],
                    (tile.position.y - st.coord_offset[1]) / st.coord_scale[1],
                );
                context.gl.uniform2f(
                    this.shader.uniforms["stitched_displacement_coord_scale"],
                    tile.size.x / st.coord_scale[0],
                    tile.size.y / st.coord_scale[1]
                );
            }

            //Used texture units. Same as slot number since empty slots are bound as null.
            let texture_count = st ? 1 : 0;
            let has_data = false;

            //Texture units that are bound to a non-null texture. If 0, skip rendering the tile.
            Object.entries(this.slots).forEach(([name, slot]) => {
                let td = Services.TileCacheService.get_tile_data(
                    slot.source,
                    tile.copy(),
                    time,
                    height
                );
                context.gl.activeTexture(context.gl.TEXTURE0 + texture_count);
                if (td && td.texture) {
                    context.gl.bindTexture(context.gl.TEXTURE_2D, td.texture);
                    context.gl.uniform2f(this.shader.uniforms[slot.shaderName + "_coord_offset"], td.coord_offset[0], td.coord_offset[1]);
                    context.gl.uniform2f(this.shader.uniforms[slot.shaderName + "_coord_scale"], td.coord_scale[0], td.coord_scale[1]);
                    has_data ||= name != "displacement";
                }
                else {
                    // Bind the gl default texture (all channels 0) to avoid rendering with an accidentally bound texture from a previous draw call
                    context.gl.bindTexture(context.gl.TEXTURE_2D, null);
                }
                context.gl.uniform1i(this.shader.uniforms[slot.shaderName + "_map"], texture_count);
                texture_count++;
            });
            if(has_data)
                context.gl.drawArrays(context.gl.TRIANGLES, buff.start, buff.length);
        });
        context.gl.disableVertexAttribArray(this.shader.attributes["position"]);
    }
}
