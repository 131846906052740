//This file is licensed under EUPL v1.2 as part of the Digital Earth Viewer

import { ViewportFilter} from "../modules/renderfilters/RenderFilter";
import { LayerPipelineFramebuffer, CompositionPipelineFramebuffer } from "../modules/PipelineFramebuffer";
import { Services } from "./Services";
import { RenderLayer } from '../modules/RenderLayer';
import { Vec2, Vec3, Mat4 } from "../modules/vecmat";
import { Parameter } from "../modules/Parameter";

export type glenv = {[name: string]: WebGLRenderingContext | any};

class DisplaySection {
    public cameraAdjustment: () => void;
    public viewportFilter: ViewportFilter;

    constructor(camAdjust: () => void, vFilter: ViewportFilter){
        this.cameraAdjustment = camAdjust;
        this.viewportFilter = vFilter;
    }
}

export class RenderService{
    context: glenv;

    layerPipeline: LayerPipelineFramebuffer;
    
    compositionPipelineFB1: CompositionPipelineFramebuffer;
    compositionPipelineFB2: CompositionPipelineFramebuffer;
    active_composition_pipeline: number = 1;

    viewportFilter: ViewportFilter;

    width: number;
    height: number;
    internalWidth: number;
    internalHeight: number;

    domeMode: string = "normal";

    createOverlay: boolean;

    sections: DisplaySection[] = [];


    constructor(gl: glenv){
        this.context = gl;
        let c = this.context.gl.canvas;
        this.width = c.width;
        this.height = c.height;
        this.internalHeight = c.height;
        this.internalWidth = c.width;
        this.domeMode = "normal";
        this.layerPipeline = new LayerPipelineFramebuffer(this.context, this.internalWidth, this.internalHeight);
        this.compositionPipelineFB1 = new CompositionPipelineFramebuffer(this.context, this.internalWidth, this.internalHeight);
        this.compositionPipelineFB2 = new CompositionPipelineFramebuffer(this.context, this.internalWidth, this.internalHeight);
        this.viewportFilter = new ViewportFilter();
        Services.SettingsService.initializeSetting(new Parameter("DomeTilt", 0, "number"));
    }

    resized(){
        let c = this.context.gl.canvas;
        this.width = c.width;
        this.height = c.height;
        if(this.layerPipeline && this.context.gl){
            this.layerPipeline.dispose();
            this.layerPipeline = new LayerPipelineFramebuffer(this.context, this.internalWidth, this.internalHeight);
        }
        if(this.compositionPipelineFB1 && this.context.gl){
            this.compositionPipelineFB1.dispose();
            this.compositionPipelineFB1 = new CompositionPipelineFramebuffer(this.context, this.internalWidth, this.internalHeight);
        }
        if(this.compositionPipelineFB2 && this.context.gl){
            this.compositionPipelineFB2.dispose();
            this.compositionPipelineFB2 = new CompositionPipelineFramebuffer(this.context, this.internalWidth, this.internalHeight);
        }
    }

    private get_write_composition_framebuffer(): CompositionPipelineFramebuffer{
        return this.active_composition_pipeline == 1 ? this.compositionPipelineFB1 : this.compositionPipelineFB2;
    }

    private get_read_composition_framebuffer(): CompositionPipelineFramebuffer{
        return this.active_composition_pipeline == 1 ? this.compositionPipelineFB2 : this.compositionPipelineFB1;
    }

    private flipComposition(){
        if(this.active_composition_pipeline == 1){
            this.active_composition_pipeline = 2;
        }else{
            this.active_composition_pipeline = 1;
        }
    }

    executeLayer(layer: RenderLayer) {
        this.layerPipeline.setInputFramebuffer();
        layer.source.execute(this.context);

        /*
         * Read current location
         */
        if(Services.RenderLayerService.isSelectedLayer(layer) && Services.SettingsService.getValueOrDefault("DomeMode", "normal") != "domemaster"){
            let mc = Services.InteractionService.lastMousePosition;
            let layerMetaData = this.layerPipeline.readInputFramebuffer(
                mc.x * this.width / window.innerWidth,
                (1 - (mc.y / window.innerHeight)) * this.height 
            );
            if(
                !layerMetaData.exists ||
                isNaN(layerMetaData.value.x1) ||
                isNaN(layerMetaData.value.x2) ||
                isNaN(layerMetaData.value.x3) ||
                isNaN(layerMetaData.value.x4)
            ){
                Services.LayerMetadataService.isUpToDate = false;
            }else{
                Services.LayerMetadataService.isUpToDate = true;
                Services.LayerMetadataService.coord = layerMetaData.coord;
                Services.LayerMetadataService.meshZ = layerMetaData.meshZ;
                Services.LayerMetadataService.normal = layerMetaData.normal;
                Services.LayerMetadataService.value = layerMetaData.value;
                Services.LayerMetadataService.z = layerMetaData.z;
                if(this.createOverlay){
                    Services.OverlayService.addOverlay(
                        layerMetaData.coord,
                        layerMetaData.z,
                        layer,
                        [
                            layerMetaData.value.x1,
                            layerMetaData.value.x2,
                            layerMetaData.value.x3,
                            layerMetaData.value.x4
                        ]
                    );
                }
            }
            this.createOverlay = false;
        }

        layer.filterPipeline.forEach(f => {
            let source_textures = this.layerPipeline.setFxFramebufferAndGetTextures()
            f.execute(this.context, source_textures);
        });

        let layer_source = this.layerPipeline.getResultTextures();
        let source = this.get_read_composition_framebuffer();
        let target = this.get_write_composition_framebuffer();
        if(layer.compositionFilter)
            layer.compositionFilter.execute(this.context, layer_source, source, target);
        else throw("Missing Composition Filter on layer \"" + layer.name +  "\"")
        this.flipComposition();
    }

    private firstFrame = true;
    public loop(){
        Services.AdaptivePerformanceService.EndMeasurement();
        let needs_rerender = Services.AdaptivePerformanceService.NeedsRerender();

        Services.AdaptivePerformanceService.SetClientDimensions(this.context.gl.canvas.clientWidth, this.context.gl.canvas.clientHeight);
        let [recommended_width, recommended_height] = Services.AdaptivePerformanceService.GetRecommendedDimensions();
        let new_domemode = Services.SettingsService.getValueOrDefault("DomeMode", "normal");
        if (this.width != recommended_width || this.height != recommended_height || this.domeMode != new_domemode ||this.firstFrame) {
            this.firstFrame = false;
            needs_rerender = true;
            this.domeMode = new_domemode;
            this.width = recommended_width;
            this.height = recommended_height;
            if(this.domeMode == "domemaster"){
                let dome_backbuffer_side = Math.sqrt(Math.PI * Math.pow(Math.min(this.height, this.width) / 2, 2) / 5) | 0;
                this.internalWidth = dome_backbuffer_side;
                this.internalHeight = dome_backbuffer_side;
            } else {
                this.internalHeight = this.height;
                this.internalWidth = this.width;
            }
            this.context.gl.canvas.width = this.width;
            this.context.gl.canvas.height = this.height;
            this.context.gl.viewport(0, 0, this.width, this.height);            
            let bb = this.context.gl.canvas.getBoundingClientRect();
            Services.PositionService.setScreenDimensions(bb.width, bb.height);
            Services.AdaptivePerformanceService.StartMeasurement();
            switch (this.domeMode) {
                case "domemaster":
                    this.sections = [
                        new DisplaySection(
                            () => {
                                Services.PositionService.recalculate_camera_transform_dome_side(
                                    Services.SettingsService.getValueOrDefault("DomeTilt", 0),
                                    3
                                );
                            },
                            ViewportFilter.dome_side(new Vec2(0.0, 1.0))
                        ),
                        new DisplaySection(
                            () => {
                                Services.PositionService.recalculate_camera_transform_dome_side(
                                    Services.SettingsService.getValueOrDefault("DomeTilt", 0),
                                    1
                                );
                            },
                            ViewportFilter.dome_side(new Vec2(0.0, -1.0))
                        ),
                        new DisplaySection(
                            () => {
                                Services.PositionService.recalculate_camera_transform_dome_top(
                                    Services.SettingsService.getValueOrDefault("DomeTilt", 0)    
                                );
                            },
                            ViewportFilter.dome_top()
                        ),
                        new DisplaySection(
                            () => {
                                Services.PositionService.recalculate_camera_transform_dome_side(
                                    Services.SettingsService.getValueOrDefault("DomeTilt", 0),
                                    0
                                );
                            },
                            ViewportFilter.dome_side(new Vec2(1.0, 0.0))
                        ),

                        new DisplaySection(
                            () => {
                                Services.PositionService.recalculate_camera_transform_dome_side(
                                    Services.SettingsService.getValueOrDefault("DomeTilt", 0),
                                    2
                                );
                            },
                            ViewportFilter.dome_side(new Vec2(-1.0, 0.0))
                        )
                    ];
                    break;    
                case "normal_red_blue":
                    this.sections = [
                        new DisplaySection(
                            () => {
                                Services.PositionService.recalculate_camera_transform_stereo_offset(.01);
                            },
                            ViewportFilter.dyed(new Vec3(1.0, 0.2, 0.0))
                        ),
                        new DisplaySection(
                            () => {
                                Services.PositionService.recalculate_camera_transform_stereo_offset(-.01);
                            },
                            ViewportFilter.dyed(new Vec3(0.0, 0.8, 1.0))
                        )
                    ]
                    break;
                case "single_projector":
                    let fr_id = Services.InitializationService.DOME_FRUSTRA.active;
                    this.sections = Services.InitializationService.DOME_FRUSTRA.frustra.map((f, i) => {
                        return new DisplaySection(
                            () => {
                                if(i == fr_id)Services.DebugService.putDebug(JSON.stringify(f, null, 2));                           
                                Services.PositionService.recalculate_camera_transform_single_frustum( 
                                    Services.SettingsService.getValueOrDefault("DomeTilt", 0),
                                    f
                                );
                            },
                            i == fr_id ? ViewportFilter.single_projector(f) : null
                        )
                    })
                    break;
                case "normal":
                default:
                    this.sections = [
                        new DisplaySection(
                            ()=>{
                                Services.PositionService.recalculate_camera_transform();
                            },
                            this.viewportFilter
                        )
                    ]
            }
            this.resized();
        } else if(needs_rerender){
            Services.AdaptivePerformanceService.StartMeasurement();
        }      
        Services.PositionService.filterUpdate();
        Services.PlaybackService.update();
        if(needs_rerender){
            Services.LayerMetadataService.isUpToDate = false;
            Services.TileCacheService.reset_queried();
            Services.RequiredTilesService.clearRequiredTiles();
            this.sections.forEach((s) => {
                s.cameraAdjustment();
                Services.RequiredTilesService.addRequiredTiles();
            })
            Services.RequiredTilesService.finalizeRequiredTiles();
            Services.StitchedTilesService.prepareStitchedTiles();
            Services.ParticlesService.prepareParticles();
            this.renderPipeline();
            Services.GLService.raiseFrameDoneEvent();
            Services.TileCacheService.load_queried();
            Services.DebugService.commitDebug();
        }
        Services.TileCacheService.frameCounter++;

        //if you just pass the function directly it loses its `this`, idk why it worked in threedee.vue but it doesn't here...
        requestAnimationFrame(() => {this.loop()});
    }

    renderPipeline(){
        this.context.gl.bindFramebuffer(this.context.gl.FRAMEBUFFER, null);
        this.context.gl.clear(this.context.gl.COLOR_BUFFER_BIT | this.context.gl.DEPTH_BUFFER_BIT);
        this.sections.forEach((section, i) => {
            if(!section.viewportFilter)return;
            this.context.gl.viewport(0, 0, this.internalWidth, this.internalHeight);
            let target_fb = this.get_write_composition_framebuffer();
            this.context.gl.bindFramebuffer(this.context.gl.FRAMEBUFFER, target_fb.framebuffer);
            target_fb.assignDrawBuffers();

            let clearcolor = Services.SettingsService.getValueOrDefault("ClearColor", Vec3.empty());
            this.context.gl.clearColor(clearcolor.x1, clearcolor.x2, clearcolor.x3, 0);
            this.context.gl.clear(this.context.gl.COLOR_BUFFER_BIT | this.context.gl.DEPTH_BUFFER_BIT);
            this.flipComposition();

            target_fb = this.get_write_composition_framebuffer();
            this.context.gl.bindFramebuffer(this.context.gl.FRAMEBUFFER, target_fb.framebuffer);
            target_fb.assignDrawBuffers();
            this.context.gl.clear(this.context.gl.COLOR_BUFFER_BIT | this.context.gl.DEPTH_BUFFER_BIT);

            section.cameraAdjustment();
            Services.DebugService.putDebug(JSON.stringify(Services.PositionService.camera_transform, null, 3));

            let has_transparency: (RenderLayer) => Boolean = (r: RenderLayer) => {
                return !!(r.compositionFilter.parameters["layer_opacity"]?.value < 1)
            }
            Services.RenderLayerService.get_visible_renderlayers().filter((x) => !has_transparency(x)).forEach(l => {
                this.executeLayer(l);
            });
            Services.RenderLayerService.get_visible_renderlayers().filter(has_transparency).forEach(l => {
                this.executeLayer(l);
            });
            if(Services.SelectionService.renderLayer){
                this.executeLayer(Services.SelectionService.renderLayer);
            }
            if(Services.ExtentVisualizationService.renderLayer){
                this.executeLayer(Services.ExtentVisualizationService.renderLayer);
            }
            this.context.gl.bindFramebuffer(this.context.gl.FRAMEBUFFER, null);
            //this.context.gl.clear(this.context.gl.COLOR_BUFFER_BIT | this.context.gl.DEPTH_BUFFER_BIT);
            if(i > 0 && this.domeMode == "normal_red_blue")this.context.gl.enable(this.context.gl.BLEND);
            this.context.gl.disable(this.context.gl.DEPTH_TEST);
            this.context.gl.blendFunc(this.context.gl.ONE, this.context.gl.ONE);
            this.context.gl.viewport(0, 0, this.width, this.height);
            section.viewportFilter.execute(this.context, this.get_read_composition_framebuffer());
            this.context.gl.disable(this.context.gl.BLEND);
            
        });
    }
}